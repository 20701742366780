/**
 * Sorts a collection of items by multiple functions and orders.
 * It prioritizes the first sort function, then the second, and so on.
 *
 * @param collection - The collection of items to sort.
 * @param sortFunctions - The functions to sort by.
 * @param order - The order to sort by.
 * @returns The sorted collection.
 */

export function orderBy<T>(
  collection: T[],
  sortFunctions: ((item: T) => any)[],
  order: 'asc' | 'desc',
): T[] {
  return [...collection].sort((a, b) => {
    for (let i = 0; i < sortFunctions.length; i++) {
      const sortFunction = sortFunctions[i];

      const aValue = sortFunction(a);
      const bValue = sortFunction(b);

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
    }

    return 0;
  });
}
