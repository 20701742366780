import {
  Icons,
  Icon,
  Image,
  Label,
  Check,
  TriggerButton,
  TriggerButtonProps,
  ellipsis,
} from '@loveholidays/design-system';
import React from 'react';

import { Image as ImageType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';

export interface ListOptionProps extends ClassNameProps {
  title: string;
  subTitle?: string;
  disabled?: TriggerButtonProps['disabled'];
  eventLabel?: TriggerButtonProps['eventLabel'];
  onTrigger: TriggerButtonProps['onTrigger'];
  trackingAction?: TriggerButtonProps['trackingAction'];
  /**
   * determines the icon when multiSelection is true
   */
  selected?: boolean;
  /**
   * if multiSelection is true, add/remove icon is present, else the moveNext
   */
  multiSelection?: boolean;
  /**
   * the icon that indicates what type of list option it is
   */
  contentIcon: Icons;

  image?: ImageType;
}

export const ListOption: React.FC<ListOptionProps> = ({
  className,
  'data-id': dataId = 'list-option',
  title,
  subTitle,
  disabled = false,
  selected = false,
  multiSelection = false,
  contentIcon,
  image,
  onTrigger,
  trackingAction,
  eventLabel,
}) => {
  const highlightedStyles = !disabled
    ? ({
        backgroundColor: 'tagprimaryDefault',
        '> span:first-of-type': {
          backgroundColor: 'backgroundWhite',
        },
      } as const)
    : undefined;

  return (
    <TriggerButton
      className={className}
      trackingAction={trackingAction}
      eventLabel={eventLabel}
      data-id={dataId}
      disabled={disabled}
      onTrigger={onTrigger}
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingY: '3xs',
        paddingX: 'l',
        '&:focus': {
          ...highlightedStyles,
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            ...highlightedStyles,
          },
        },
      }}
    >
      <span
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'backgroundLightsubtle',
          border: 'solid',
          borderWidth: 'outlinedStrokeWeight',
          borderColor: 'strokeLightsubtle',
          padding: image ? null : '2xs',
          borderRadius: '8',
          overflow: 'hidden',
        }}
      >
        {image ? (
          <Image
            src={image.url}
            alt={image.description}
            width={44}
            height={44}
            sx={{
              width: 44,
              height: 44,
            }}
          />
        ) : (
          <Icon
            name={contentIcon}
            size="20"
            sx={{
              color: disabled ? 'iconDisabled' : 'iconDefault',
            }}
          />
        )}
      </span>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginLeft: 'xs',
          maxWidth: ({ space, sizes }) =>
            `calc(100% - ${2 * space.l}px - ${sizes[36]}px - ${sizes[28]}px)`,
        }}
      >
        <Label
          variant="medium"
          sx={{
            color: disabled ? 'textDisabled' : 'textDefault',
          }}
        >
          {title}
        </Label>
        {subTitle && (
          <Label
            variant="extrasmall"
            sx={{
              ...ellipsis,
              color: disabled ? 'textDisabled' : 'textDimmedmedium',
              width: '100%',
            }}
          >
            {subTitle}
          </Label>
        )}
      </div>
      {!disabled && (
        <span
          sx={{
            display: 'flex',
            marginLeft: 'auto',
          }}
        >
          {multiSelection ? (
            <Check
              onChange={() => {}}
              checked={selected}
              disabled={disabled}
              size="28"
            />
          ) : (
            <Icon
              name="Actions/MoveNext"
              size="20"
            />
          )}
        </span>
      )}
    </TriggerButton>
  );
};
