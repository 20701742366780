import { Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useMemo } from 'react';

import { ListOption } from '../SuggestionList/ListOption';
import { Option } from '../types';
import { orderBy } from '@Core/helpers/orderBy';

export const isAnyAirport = (value: string) => value === '*';

interface DepartureSectionProps {
  options: Option[];
  onClick: (id: string, isSelected: boolean) => void;
}

export const DepartureSection: React.FC<DepartureSectionProps> = ({ options, onClick }) => {
  const { t } = useTranslation();

  const sortedOptions = useMemo(() => orderBy(options, [(d) => d.selected], 'desc'), [options]);

  if (!sortedOptions || sortedOptions.length === 0) {
    return (
      <Label
        sx={{
          display: 'block',
          marginTop: '2xl',
        }}
        variant="medium"
      >
        {t('noMatchesFound')}
      </Label>
    );
  }

  const anyAirport = sortedOptions
    .filter((d) => isAnyAirport(d.value))
    .map((d) => (
      <React.Fragment key={d.value}>
        <Label
          sx={{
            display: 'block',
            marginTop: 'l',
            marginBottom: '2xs',
          }}
          variant="small"
        >
          {t('searchUi.departureInput.anyAirportTitle')}
        </Label>
        <div
          sx={{
            marginX: '-xs',
          }}
        >
          <ListOption
            data-id="any-airport-list-option"
            onTrigger={() => onClick(d.value, !!d.selected)}
            title={d.label}
            contentIcon="Content/PlaceAirport"
            selected={d.selected}
          />
        </div>
      </React.Fragment>
    ));

  return (
    <React.Fragment>
      {anyAirport}
      <Label
        variant="small"
        sx={{
          display: 'block',
          marginTop: anyAirport.length ? 'xl' : 'l',
          marginBottom: '2xs',
        }}
      >
        {t('searchUi.departureInput.departureSectionTitle')}
      </Label>
      <div
        data-id="departure-list"
        sx={{
          marginX: '-xs',
        }}
      >
        {sortedOptions
          .filter((d) => !isAnyAirport(d.value))
          .map((d) => (
            <ListOption
              data-id="departure-list-option"
              onTrigger={() => onClick(d.value, !!d.selected)}
              key={d.value}
              title={d.label}
              subTitle={d.secondaryLabel}
              contentIcon="Content/PlaceAirport"
              multiSelection
              selected={d.selected}
              disabled={!d.available}
            />
          ))}
      </div>
    </React.Fragment>
  );
};
